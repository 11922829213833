import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./appRoutes";
import {srv_getTachiku} from "./services/base";
import {setCacheEncryption} from "./utils/cache";

import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

let gDidMount=false;
let cookieSecret=null;
let cookieName=null;


export default function AppRouter(props) {
  
  // Our backend basic info
  const [version, setVersion] = useState(""); 
  const [isDebug, setIsDebug] = useState(false); 
  const [host, setHost] = useState(""); 

  // Authentication info from cookie
  const [authUsername, setAuthUsername] = useState(null); 
  const [authWalletAddress, setAuthWalletAddress] = useState(null); 
  const [authWalletId, setAuthWalletId] = useState(null);
  const [authProvider, setAuthProvider] = useState(null);

  // Localstore encrypt secret
  const [cacheSecret, setCacheSecret] = useState(null);

  const navigate = useNavigate();

  
  useEffect(() => {
    if(!gDidMount) {

      gDidMount=true;
      
      // get SIWW version
      srv_getTachiku()
        .then(dataInfo => {
          if(dataInfo && dataInfo.data) {
            setVersion(dataInfo.data.version);
            setIsDebug(dataInfo.data.isDebug);
            setHost(dataInfo.data.host);

            //TODO ... need to pass the key from server in a more secure way than this
            setCacheSecret(dataInfo.data.cache_secret);
            setCacheEncryption(dataInfo.data.cache_secret);

            cookieName=dataInfo.data.isDebug? 'jwt_DEBUG_token_'+dataInfo.data.app : 'jwt_token_'+dataInfo.data.app;
          }
        })
    }
  }, []);

  const onUpdateCookieInfo = (token) => {
    let _token=token? token : Cookies.get(cookieName);
    if(_token) {
      let decoded = jwt_decode(_token);

      setAuthUsername(decoded.username);
      setAuthWalletAddress(decoded.wallet_address);
      setAuthWalletId(decoded.provider_wallet);          
      setAuthProvider(decoded.blockchain);          

      Cookies.set(cookieName, _token, {
        expires: "72h", 
        path: '' 
      });    
    }
  }

  const loginUserWithCookie = (objParam) => {
      cookieSecret=objParam.secret;
      onUpdateCookieInfo();
  }

  // shitty React.. the only good way to redirect in one place...
  const onRedirect = async(_route) => {
    navigate(_route);
  }

  return (
      <>     
        <AppRoutes 
          onSoftRedirect = {onRedirect}

          // 
          version = {version}
          isDebug = {isDebug}
          host = {host}

          // cookie
          authUsername = {authUsername}
          authWalletAddress = {authWalletAddress}
          authWalletId = {authWalletId}
          authProvider = {authProvider}

          loginUserWithCookie = {loginUserWithCookie}
        />
      </>
  );
}
