import React, { Component, Suspense } from "react";
import {Route, Routes, Navigate } from 'react-router-dom';

import { WidgetLoading} from "./components/UI/widgetLoading";

// Need to lazy load those or the CSS is being screwed up (all marketing, doc, and app css loaded in all cases)
//import AppCanvas from "./appCanvas";
const AppCanvasPrivate  = React.lazy(() => import ("./appCanvasPrivate"));
const AppCanvasPublic  = React.lazy(() => import ("./appCanvasPublic"));
const AppLegal  = React.lazy(() => import ("./appLegal"));
const AppMarketing = React.lazy(() => import ("./_Marketing/marketing"));
const AppDoc  = React.lazy(() => import ("./appDoc"));

// this is how we deal with Hard redirects (reload all) from menu
const RouteRedirect = (props) => {
  const onHardRedirect =(path) => {
      window.location.replace(path);
  }
  return (
    <>
      {onHardRedirect(props.to)}
    </>
  );
}

/* 
 *      Routing class
 */

class AppRoutes extends Component {

/*
*     all for APP routes 
*/

  isApp() {
    return window.location.pathname.toLowerCase().substring(0, 4) === "/app"
  }

  getAppPrivateRoute(_route) {
          let ts=new Date().getTime();
          return (
            <Route path={_route}
              element={
              <Suspense fallback={
                  <WidgetLoading
                    isVisible= {true}
                    hasSpinner= {false}
                    text= 'Loading App...'
                    fullHeight = {true}
                    image= "/assets/images/emc2/EMC2_joker_20.jpg"
                    />
              }>
                <AppCanvasPrivate
                  onRedirect = {this.props.onSoftRedirect.bind(this)}
                  isDebug = {this.props.isDebug}
                  forceRefresh = {ts}

                  // authenticated user
                  authUsername = {this.props.authUsername}
                  authWalletAddress = {this.props.authWalletAddress}
                  authWalletId = {this.props.authWalletId}
                  authProvider = {this.props.authProvider}
                  loginUserWithCookie = {this.props.loginUserWithCookie}

                />
              </Suspense> 
            }
          />
        );
  }

  getAppPublicRoute(_route) {
    let ts=new Date().getTime();
    return (
        <Route path={_route}
          element={
          <Suspense fallback={
              <WidgetLoading
                isVisible= {true}
                hasSpinner= {false}
                text= 'Loading App...'
                fullHeight = {true}
                />
          }>
            <AppCanvasPublic 
              onRedirect = {this.props.onSoftRedirect.bind(this)}
              isDebug = {this.props.isDebug}
              forceRefresh = {ts}
              />
          </Suspense> 
        }
      />
    );
  }



/*
*     all for DOC routes 
*/

  getDocRoutes() {
    return (
       <Route  path="/doc" element={
        <Suspense fallback={
                <WidgetLoading
                      isVisible= {true}
                      hasSpinner= {true}
                      text= 'Loading Documentation...'
                      fullHeight = {true}
                      />
                }>
                  <AppDoc 
                    location={window.location}
                    version={this.props.version}
                  />
                </Suspense> 
      } exact />
    )
  }

/*
*     all for Marketing Website routes 
*/

  getMarketingRoutes() {
    return (
      <>
            <Route  path="/" element={
              <Suspense fallback={
                <WidgetLoading
                  isVisible= {false}
                  hasSpinner= {true}
                  text= ''
                  fullHeight = {true}
                  //image= "/assets/images/emc2/EMC2_joker_20.jpg"
                />
              }>
              <AppMarketing 
                  location={window.location}
                />
              </Suspense> 
            } />
      
            <Route  path="/terms" element={        
              <Suspense fallback={
                <WidgetLoading
                      isVisible= {true}
                      hasSpinner= {true}
                      text= 'Loading...'
                      fullHeight = {true}
                      />
                }>   
                <AppLegal
                    isTerms={true}
                    isPrivacy={false}
                    location={window.location}
                    version={this.props.version}
                />
                </Suspense> 
            } exact />
      
            <Route  path="/privacy" element={     
              <Suspense fallback={
                <WidgetLoading
                      isVisible= {true}
                      hasSpinner= {true}
                      text= 'Loading...'
                      fullHeight = {true}
                      />
                }>   
                  <AppLegal
                      isTerms={false}
                      isPrivacy={true}
                      location={window.location}
                      version={this.props.version}
                  />
                </Suspense> 
            } exact />
      </>
    );
  }

  render() {
    return (
      <>
        
        <Routes>            
          {this.getDocRoutes()}
          {this.getMarketingRoutes()}

          {/* Routes for APP */}
          <Route path="app" >

            {/* Remember any route added in here needs to be opened on backend too (otherwise will not allow redirect to app) */}

            {this.getAppPrivateRoute("dashboard")}
            {this.getAppPrivateRoute("create")}
            {this.getAppPublicRoute("help")}
            {this.getAppPublicRoute("artwall")}
            {this.getAppPrivateRoute("settings")}
            {this.getAppPrivateRoute("preview")}
            {this.getAppPrivateRoute("mint")}
            {this.getAppPublicRoute("view")}
            {this.getAppPrivateRoute("gallery")}
            {this.getAppPrivateRoute("admin")}
            {this.getAppPrivateRoute("auth")}
            {this.getAppPrivateRoute("logout")}
            
            <Route 
              path=""
              element={<Navigate 
                to = "/app/dashboard"
                replace={true}
              />} 
            />

          </Route>

          {/* Routes to redirect to main sections */}
          <Route path="redirect">
            <Route  path="home" element={<RouteRedirect
              to = "/"
            />} />
            <Route  path="doc" element={<RouteRedirect
              to = "/doc"
            />} />
            <Route  path="*" element={<RouteRedirect
              to = "/app/dashboard"
            />} />
          </Route>

          <Route 
              path="*"
              element={<Navigate 
                to = "/app/dashboard"
                replace={true}
              />} 
          />

        </Routes>

  </>
  );
  }
}

export default AppRoutes;
